<script>
	import AutoComplete from "./components/AutoComplete.svelte"
	export let selectedItem = [];

	async function getItems(keyword) {
		const url = '/wp-json/search/v1/all?query=' + encodeURIComponent(keyword);
		const response = await fetch(url);
		const results = await response.json();
		return results;
	}

	const valueItem = item => {
		if(item.url) location.href = item.url;
	}


	const setItemOutput = item => {
		if(item && item.title) {
			return `<div class="search-item">
						<span>${item.title}</span> <small>${item.type}</small>
					</div>`
		}
	}
</script>


<div id="search-component">
	<form action="/" method="get">
		<AutoComplete
			placeholder="Cosa stai cercando?"
			searchFunction={getItems} 
			valueFunction={valueItem}
			bind:selectedItem={selectedItem}
			labelFieldName="title"
			labelFunction={setItemOutput}
			minCharactersToSearch={3}
			selectFirstIfEmpty={false}
			name="search_autocomplete"
			showClear={false}
			hideArrow={true}
		/>
		<button type="submit" id="search-submit" disabled></button>
	</form>
</div>